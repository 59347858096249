<template>

 <b-row      right  
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user">


<div v-b-toggle.sidebar-right >
    <img v-if="isProfileVerificated" title="Usuario verificado" alt="Usuario verificado" class="badgeVerificacion" src="/img/verificadoFinal.svg" />
    <img v-else alt="Usuario no verificado" title="Usuario no verificado" class="badgeVerificacion" src="/img/no_verificadoFinal.svg" />

        <b-avatar
        size="40"
        variant="light-primary"

        
      >
      {{ name }}

      </b-avatar>

    </div>


    <b-sidebar
      id="sidebar-right"
      right
      backdrop
      shadow
      :z-index="z_index"
      lazy
      v-model="isSidebarOpen"
      @change="toggleBodyScrollbar"
    >


      <perfil :display-name="displayName" :promo-code="promoCode" :alias="alias" :isSidebar="true" :user-id="userId" :token-auth="tokenAuth" :is-profile-verificated="isProfileVerificated"  />
   

 <b-row style="    margin-top: 65px;">
  <b-col  @click="logout" sm="12" md="12" lg="12" xl="12" class="div_session">
 

 <div class="menu_opcion" style="margin: 0px;border-radius: 0px;padding: 12px 10px !important;">

   <feather-icon
           icon="LogOutIcon"
           size="18"
           style="margin-right: 5px;"
           /> Cerrar sesión

 </div>

      

     

 </b-col>
 </b-row>


    </b-sidebar >

 </b-row>


 
</template>

<script>
import {

  BSidebar, VBToggle, BRow, BCol, BAvatar 
} from 'bootstrap-vue'


export default {
  components: {
    BSidebar,
    Perfil: () => import('../../../../../views/pages/others/Perfil.vue'),
    VBToggle,
    BAvatar,
    BRow,
    BCol,
  },
   directives: {
    'b-toggle': VBToggle,
  },
  props: ['userId','tokenAuth', 'displayName','alias','promoCode', 'isProfileVerificated'],
  data() {


    let name= this.displayName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
 


    return {
      isSidebarOpen:false,
      z_index:10000000,
      name:name,
      body: document.getElementsByTagName('body')[0],
    }
  },
  mounted() {

  

    this.$eventBus.$on('closeSidebar', () => {
     
      this.isSidebarOpen = false;
 
    });


  },
  methods: {



    toggleBodyScrollbar(visible) {



      if(visible){

  

        this.body.classList.add("overflow-hidden");

      }else{
        this.body.classList.remove("overflow-hidden");
      }

    },
    logout() {
      this.exit(this.userId,this.tokenAuth, true);


    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/menu.scss';
</style>
